import { default as account_45createdRpvuMqMqJPMeta } from "/home/denis/sources/sos-amiante/client/pages/account-created.vue?macro=true";
import { default as account3SgTve8dzXMeta } from "/home/denis/sources/sos-amiante/client/pages/account.vue?macro=true";
import { default as confirmationL7Zg10cWSuMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/confirmation.vue?macro=true";
import { default as informationsXaHh4VdLPiMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/informations.vue?macro=true";
import { default as introET0sDw01JGMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/intro.vue?macro=true";
import { default as location81MTv57zWNMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/location.vue?macro=true";
import { default as create_45account0txjptehDkMeta } from "/home/denis/sources/sos-amiante/client/pages/create-account.vue?macro=true";
import { default as create_45expert_45accountiA24Xuzv6VMeta } from "/home/denis/sources/sos-amiante/client/pages/create-expert-account.vue?macro=true";
import { default as indexhAJz01qG8EMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/index.vue?macro=true";
import { default as _91id_93kzpz9PxBpNMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/my-requests/[id].vue?macro=true";
import { default as indexd8URb4jOdSMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/my-requests/index.vue?macro=true";
import { default as _91id_93LURdRxz1u4Meta } from "/home/denis/sources/sos-amiante/client/pages/expert/public-requests/[id].vue?macro=true";
import { default as index2MFkfurPYbMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/public-requests/index.vue?macro=true";
import { default as settingsJ6MQtFOtcgMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/settings.vue?macro=true";
import { default as forgot_45passwordGKiaU4QPLyMeta } from "/home/denis/sources/sos-amiante/client/pages/forgot-password.vue?macro=true";
import { default as indexjNdjUrOaiGMeta } from "/home/denis/sources/sos-amiante/client/pages/index.vue?macro=true";
import { default as indexjymy4wSnhPMeta } from "/home/denis/sources/sos-amiante/client/pages/kit/index.vue?macro=true";
import { default as loginnHZKh9InSSMeta } from "/home/denis/sources/sos-amiante/client/pages/login.vue?macro=true";
import { default as _91id_93gnCjwsEsb2Meta } from "/home/denis/sources/sos-amiante/client/pages/my-requests/[id].vue?macro=true";
import { default as indexjN0AlkBlE0Meta } from "/home/denis/sources/sos-amiante/client/pages/my-requests/index.vue?macro=true";
import { default as indexF0ZSTJXjfeMeta } from "/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/index.vue?macro=true";
import { default as new_45pictures0ryYis1jN6Meta } from "/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/new-pictures.vue?macro=true";
import { default as indexEg1gXeZeyzMeta } from "/home/denis/sources/sos-amiante/client/pages/orders/index.vue?macro=true";
import { default as payment_45validationpbzo0JVrV0Meta } from "/home/denis/sources/sos-amiante/client/pages/payment-validation.vue?macro=true";
import { default as paymentYgngP89evPMeta } from "/home/denis/sources/sos-amiante/client/pages/payment.vue?macro=true";
import { default as commentZiOmGcLZlnMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/comment.vue?macro=true";
import { default as construction_dateqpyPAKW1MiMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/construction_date.vue?macro=true";
import { default as introeYNNMpQh0sMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/intro.vue?macro=true";
import { default as locationiQPrEJiLBIMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/location.vue?macro=true";
import { default as picturesiiaJlFKt3dMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/pictures.vue?macro=true";
import { default as profileQAow03oYFQMeta } from "/home/denis/sources/sos-amiante/client/pages/profile.vue?macro=true";
import { default as additional_45informationsWTu48JK5fdMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/additional-informations.vue?macro=true";
import { default as deadlineZxdLu1tjwKMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/deadline.vue?macro=true";
import { default as how_45to_45morervSb3iAyqKMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/how-to-more.vue?macro=true";
import { default as how_45toc1MECWIj4gMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/how-to.vue?macro=true";
import { default as edityja1TnNNOCMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/samples/edit.vue?macro=true";
import { default as indexQS80WQLsybMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/samples/index.vue?macro=true";
import { default as sending6YVswWU59QMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/sending.vue?macro=true";
export default [
  {
    name: "account-created",
    path: "/account-created",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/account-created.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/account.vue")
  },
  {
    name: "contact-confirmation",
    path: "/contact/confirmation",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/confirmation.vue")
  },
  {
    name: "contact-informations",
    path: "/contact/informations",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/informations.vue")
  },
  {
    name: "contact-intro",
    path: "/contact/intro",
    meta: introET0sDw01JGMeta || {},
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/intro.vue")
  },
  {
    name: "contact-location",
    path: "/contact/location",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/location.vue")
  },
  {
    name: "create-account",
    path: "/create-account",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/create-account.vue")
  },
  {
    name: "create-expert-account",
    path: "/create-expert-account",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/create-expert-account.vue")
  },
  {
    name: "expert",
    path: "/expert",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/index.vue")
  },
  {
    name: "expert-my-requests-id",
    path: "/expert/my-requests/:id()",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/my-requests/[id].vue")
  },
  {
    name: "expert-my-requests",
    path: "/expert/my-requests",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/my-requests/index.vue")
  },
  {
    name: "expert-public-requests-id",
    path: "/expert/public-requests/:id()",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/public-requests/[id].vue")
  },
  {
    name: "expert-public-requests",
    path: "/expert/public-requests",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/public-requests/index.vue")
  },
  {
    name: "expert-settings",
    path: "/expert/settings",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/settings.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/index.vue")
  },
  {
    name: "kit",
    path: "/kit",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/kit/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/login.vue")
  },
  {
    name: "my-requests-id",
    path: "/my-requests/:id()",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/my-requests/[id].vue")
  },
  {
    name: "my-requests",
    path: "/my-requests",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/my-requests/index.vue")
  },
  {
    name: "orders-order_uuid",
    path: "/orders/:order_uuid()",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/index.vue")
  },
  {
    name: "orders-order_uuid-new-pictures",
    path: "/orders/:order_uuid()/new-pictures",
    meta: new_45pictures0ryYis1jN6Meta || {},
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/new-pictures.vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/index.vue")
  },
  {
    name: "payment-validation",
    path: "/payment-validation",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/payment-validation.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/payment.vue")
  },
  {
    name: "picture-comment",
    path: "/picture/comment",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/comment.vue")
  },
  {
    name: "picture-construction_date",
    path: "/picture/construction_date",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/construction_date.vue")
  },
  {
    name: "picture-intro",
    path: "/picture/intro",
    meta: introeYNNMpQh0sMeta || {},
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/intro.vue")
  },
  {
    name: "picture-location",
    path: "/picture/location",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/location.vue")
  },
  {
    name: "picture-pictures",
    path: "/picture/pictures",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/pictures.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/profile.vue")
  },
  {
    name: "sample-additional-informations",
    path: "/sample/additional-informations",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/additional-informations.vue")
  },
  {
    name: "sample-deadline",
    path: "/sample/deadline",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/deadline.vue")
  },
  {
    name: "sample-how-to-more",
    path: "/sample/how-to-more",
    meta: how_45to_45morervSb3iAyqKMeta || {},
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/how-to-more.vue")
  },
  {
    name: "sample-how-to",
    path: "/sample/how-to",
    meta: how_45toc1MECWIj4gMeta || {},
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/how-to.vue")
  },
  {
    name: "sample-samples-edit",
    path: "/sample/samples/edit",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/samples/edit.vue")
  },
  {
    name: "sample-samples",
    path: "/sample/samples",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/samples/index.vue")
  },
  {
    name: "sample-sending",
    path: "/sample/sending",
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/sending.vue")
  }
]