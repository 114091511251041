import {defineNuxtRouteMiddleware, navigateTo} from "nuxt/app";
import {useHttp} from "~/composables/httpClient";
import {useUser} from "~/stores/user";
import {useFlow} from "~/stores/flow";

export default defineNuxtRouteMiddleware(async (to, from) => {


    // function getKeyByValue(object, value) {
    //     return Object.keys(object).find(key => object[key] === value);
    // }
    //
    // let origin = window.location.origin
    //
    // if(Object.values(useRuntimeConfig().public.appUrl).includes(origin))
    // {
    //     useLanguage().lang.value = getKeyByValue(useRuntimeConfig().public.appUrl, origin)
    // }

    if(to.query.lang){
        useLanguage().setLanguage(to.query.lang)
    }

    if (to.path.indexOf('picture') > 0) {
        useFlow().flow = 'picture'
    } else if (to.path.indexOf('self') > 0) {
        useFlow().flow = 'sample'
    } else if (to.path.indexOf('kit') > 0) {
        useFlow().flow = 'kit'
    } else if (to.path.indexOf('contact') > 0) {
        useFlow().flow = 'contact'
    }


    switch (to.path) {
        case '/login':
        case '/create-account':
        case '/forgot-password':
        case '/create-expert-account':
        case '/create-expert-account/':
        case '/account-created':
            break;
        case '/sample/how-to':
        case '/sample/how-to/':
        case '/sample/how-to-more':
        case '/sample/how-to-more/':
            let {data: user, error} = await useHttp().get('/users/me');
            if (error.value) {
                break;
            }
            useUser().user = user.value;
            break;
        case '/logout':
            useUser().user = undefined;
            window.localStorage.removeItem('token')
            return navigateTo('/login')
        case '/saferpay':
            return;
        default:
            if (window.localStorage.getItem('token')) {

                if (localStorage.getItem('redirect_from')) {
                    let redirectFrom = window.localStorage.getItem('redirect_from')
                    localStorage.removeItem('redirect_from')
                    return navigateTo(redirectFrom)
                }

                let {data: user, error} = await useHttp().get('/users/me');

                if (error.value) {
                    localStorage.removeItem('token')
                    localStorage.setItem('redirect_from', to.path)
                    return navigateTo('/login')
                }
                useUser().user = user.value;
            } else {
                localStorage.setItem('redirect_from', to.path)
                return navigateTo('/login')
            }
    }

})
